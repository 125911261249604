@import 'prismjs/themes/prism';
@import 'prismjs/plugins/line-highlight/prism-line-highlight';
@import '@frontend/design-system/src/styles/_variables';

.code-snippet {
  &.language-javascript,
  &.language-diff-javascript {
    font-size: 12px;
    background: $gray-A1;
    color: #70787a;
    margin: 0;
  }

  &.line-numbers {
    .line-numbers-rows {
      border-right: 0;
      & > span:before {
        color: #c4c8cb;
      }
    }
  }

  .diff-highlight {
    .token.deleted:not(.prefix),
    .token.inserted:not(.prefix) {
      display: inline;
    }
  }

  .token {
    &.punctuation {
      color: #70787a;
    }

    &.property,
    &.tag,
    &.boolean,
    &.number,
    &.constant,
    &.symbol,
    &.deleted {
      color: #c1434f;
    }

    &.selector,
    &.attr-name,
    &.string,
    &.char,
    &.builtin,
    &.inserted {
      color: #469b76;
    }

    &.operator,
    &.entity,
    &.url,
    &.regex,
    &.important,
    &.variable {
      color: #e08a45;
    }

    &.atrule,
    &.attr-value,
    &.keyword,
    &.function,
    &.class-name {
      color: #3e76ad;
    }
  }
}

.code-snippet-file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gray-50;
  color: $gray-800;
  font-family: $font-mono;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 24px;
  color: $gray-800;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid $gray-100;
  border-bottom: none;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 10px;
  }

  .code-snippet-copy-btn {
    background: none;
    padding: 0 !important;

    span {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }
}

.code-snippet-error {
  code[class*='language-'],
  pre[class*='language-'] {
    color: #70787a;
    margin: 0;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: #fff;
  }

  .token.punctuation {
    color: #70787a;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #c1434f;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #469b76;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.regex,
  .token.important,
  .token.variable {
    color: #e08a45;
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword,
  .token.function,
  .token.class-name {
    color: #3e76ad;
  }

  .line-numbers .line-numbers-rows {
    border-right: 0;
  }

  .line-numbers-rows > span:before {
    color: #c4c8cb;
  }

  .line-highlight {
    background: linear-gradient(
      to right,
      hsla(24, 20%, 50%, 0.1) 100%,
      hsla(24, 20%, 50%, 0)
    );

    &:before {
      display: none;
    }
  }

  .test-error-code-frame pre[data-line] {
    padding-left: 0.5em;
    position: relative;
  }
}

.code-snippet-container-v2 {
  display: flex;
  border-radius: 0 0 4px 4px;
  border: 1px solid $gray-100;
  position: relative;

  .loading & {
    flex-direction: column;
    padding: 6px 0;
    position: relative;

    &:before {
      border-right: solid 1px $gray-100;
      content: '';
      position: absolute;
      top: 0;
      left: 38px;
      bottom: 0;
    }
  }

  .copy-btn-container {
    top: 0;
    right: 0;
    position: absolute;

    button {
      border-radius: 0 3px 0 4px;
    }

    .code-snippet-button-wrapper {
      transition: border-color 0.3s ease;
      box-sizing: border-box;

      .code-snippet-copy-btn {
        outline: none;
        display: flex;
        align-items: center;

        background-color: $indigo-50;
        color: $indigo-500;
        font-weight: normal;

        cursor: pointer;
        height: 32px;

        .copy-icon {
          margin-right: 4px;
        }
        &:hover {
          box-shadow: 0 0 0 1px $indigo-100;
        }
      }
      &:hover {
        box-shadow: 0 0 0 1px $indigo-100;
      }
    }
  }

  .col-nums {
    margin-right: 4px;
    padding: 8px 8px 8px 24px;
    border-right: 1px solid $gray-100;
    background-color: $gray-50;
    color: $gray-500;
    font-family: monospace;
  }

  .col-diff {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 0;

    .row-diff {
      border-left: 2px solid $jade-400;
      align-items: center;
      display: flex;
      flex-basis: 100%;
    }
  }

  .col-code {
    position: relative;
    padding: 8px 8px 8px 16px;
    overflow: auto;
    width: 100%;
    pre {
      margin: 0;
      padding: 0;
      line-height: 1.65;
      background-color: transparent;
    }
    code[class*='language-'],
    pre[class*='language-'] {
      color: $gray-700;
      font-size: 12px;
    }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: $red-500;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
      color: $jade-500;
    }

    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string,
    .token.regex,
    .token.important,
    .token.variable {
      color: $orange-500;
    }

    .token.operator,
    .token.punctuation {
      color: $gray-500;
    }

    .token.atrule,
    .token.attr-value,
    .token.class-name {
      color: $indigo-500;
    }

    .token.keyword,
    .token.function {
      color: $purple-500;
    }

    .token.comment {
      color: $gray-500;
    }

    .code-expand {
      position: absolute;
      background: linear-gradient(
        360deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 88.02%
      );
      border-radius: 0px;
      bottom: 0;
      width: 95%;
      height: 100%;
      display: flex;
      align-items: end;
      padding-bottom: 20px;

      .expand-btn {
        margin-left: auto;
        margin-right: auto;
        border-radius: 48px !important;
        padding: 6px 12px !important;
        background: white;
        .expand-icon {
          margin-right: 4px;
        }
      }
    }
  }
}

.code-snippet.design-system-candidate {
  .code-snippet-container-v2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .col-nums {
      background-color: $gray-50;
      color: $gray-500;
      margin-right: 0;
      font-size: 14px;
      line-height: 24px;
      font-family: $font-mono;
    }

    .col-code {
      code {
        background-color: white !important;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
      }

      .token.comment {
        color: $gray-700;
      }

      .code-snippet-copy-btn {
        span {
          display: none;
        }
      }
    }
  }
}

.code-snippet.test-replay {
  .code-snippet-container-v2 {
    border-color: $gray-800;

    .col-nums {
      font-family: $font-mono;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      text-align: right;
      color: $gray-500;
      background-color: transparent;
      border-color: $gray-800;
      margin-right: 0;
    }

    .col-code {
      background-color: $gray-1000;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      .language-markup {
        font-family: $font-mono;
        text-shadow: none;
        color: $gray-300;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      .language-markup::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: 10px;
        background-color: transparent;
      }

      .language-markup::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
      }

      .language-markup::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: none;
        min-width: 30px;
        background-color: $gray-600;
      }
    }
  }
}

.code-snippet.test-generation {
  &.loading {
    min-width: 500px;
  }

  .code-snippet-file-name {
    background: none;
    color: $gray-700;
    line-height: 36px;
    padding-bottom: 0;
    padding-top: 0;
  }

  .code-snippet-container-v2 {
    border-radius: 0;

    .col-nums {
      background: none;
      border: none;
      color: $gray-700;
      padding-left: 8px;
      text-align: right;

      .row-num {
        line-height: 24px;
      }
    }

    .col-code {
      padding-left: 8px;

      code {
        line-height: 24px;
      }

      .token.punctuation,
      .token.operator {
        color: $gray-900;
      }

      .token.comment {
        color: $gray-700;
      }
    }
  }
}
